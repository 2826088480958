import { Link } from "react-router-dom";
import React from "react";
import { handleClickScroll, scrollToTop } from "../../lib/helpers";

const FooterTwo = () => {
  return (
    <footer>
      <div className="footer-area-two">
        <div className="container custom-container-four">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-8">
                <div className="footer-menu-two">
                  <ul className="navigation">
                    <li>
                      <Link
                        to="#about"
                        onClick={() => handleClickScroll("about")}
                        style={{ color: "#fff" }}
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#our-service"
                        onClick={() => handleClickScroll("our-service")}
                        style={{ color: "#fff" }}
                      >
                        Our Service
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="#our-team"
                        onClick={() => handleClickScroll("our-team")}
                        style={{ color: "#fff" }}
                      >
                        Our Team
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#contact"
                        onClick={() => handleClickScroll("contact")}
                        style={{ color: "#fff" }}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/niqcode-digital"
                        style={{ color: "#fff" }}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" style={{ color: "#fff" }}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> 
                    <li>
                      <a href="#" style={{ color: "#fff" }}>
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" style={{ color: "#fff" }}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-lg-5">
                <div className="copyright-text">
                  <p style={{ color: "#fff" }}>
                    &copy; {new Date().getFullYear()} Niqcode. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="scroll-up text-center">
                  <button
                    className="scroll-to-target"
                    data-target="html"
                    onClick={scrollToTop}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  {/* <span style={{ color: "#fff" }}>scroll Top</span> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="footer-bottom-menu">
                  <ul>
                    <li>
                      <Link to="https://wa.me/6282118272883" style={{ color: "#fff" }}>
                        <i className="fab fa-whatsapp"></i>&nbsp;&nbsp;+6282118272883
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" style={{ color: "#fff" }}>
                        Privacy Policy
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
