import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
// import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
// import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import { Trans } from "react-i18next";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        <WhoWeAre />

        <TopPartners />
        <WhyChooseUs />
        {/* <Sales /> */}

        <div className="area-bg">
          <Roadmap />
          {/* <WhitePaper /> */}
        </div>

        {/* <TeamOne /> */}
        <div>
          <div className="section-title text-center mb-4">
            {/* <span className="sub-title">why Choose us </span> */}
            <h2 className="title">
              <Trans
                i18nKey="address.title"
                components={{ span: <span /> }}
              />
            </h2>
          </div>
          <div className="section-title text-center mb-50">
            {/* <span className="sub-title">why Choose us </span> */}
            <span>
              <Trans
                i18nKey="address.location"
                components={{ span: <span /> }}
              />
            </span>
          </div>
          <iframe
            title="Niqcode Office"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4792849894507!2d106.82264599999999!3d-6.2003249999999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f57d407678a7%3A0xe08ddc7975360de3!2sTatapuri%20Building!5e0!3m2!1sen!2ssg!4v1734791281345!5m2!1sen!2ssg"
            width="100%"
            height="600"
            style={{ border: 0 }}
            // style="border:0;"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <ContactOne />
      </main>
    </LayoutOne>
  );
};

export default Home;
